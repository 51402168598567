import React from 'react';
 // requires a loader
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import carouselBackground from './carousel.jpg';

import './App.css';

const createCarouselItemImage = (index, options = {}) => (
  <div key={index}>
      <img src={carouselBackground} alt='CarouselBackgroundImage' />
      <p style={{fontSize: 30, color: '#fff'}} className="legend">Welcome!</p>
  </div>
);

const baseChildren = <div>{[1].map(createCarouselItemImage)}</div>;

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: true,
  showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: false,
  thumbWidth: 100,
  selectedItem: 0,
  interval: 2000,
  transitionTime: 500,
  swipeScrollTolerance: 5,
  ariaLabel: undefined,
});

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <a style={{ textAlign: 'center', fontSize: 15, color: '#fff', textDecoration: 'none' }} href="mailto:support@seriousmindware.com">support@seriousmindware.com</a>
          <p style={{ textAlign: 'center', fontSize: 15, color: '#fff' }}>845-520-7450</p>
        </div>
      </header>
      <body>
        <a style={styles.navbarbrand} href="/">SERIOUS MINDWARE 3.0</a>
        <Carousel {...getConfigurableProps()}>{baseChildren.props.children}</Carousel>
        <h1 style={{ fontStyle: 'italic' }}>What Can We Build <span style={{ color: 'cornflowerblue'}}>For You?</span></h1>
        <footer style={{ backgroundColor: '#282c34' }} id="footer">
          <div class="container">
            <div class="row">
              <div class="col-md-3 col-sm-6 margin30">
                <div class="footer-col">
                  <h3 style={{ color: '#fff', marginTop: 30, paddingTop: 30 }}>About Serious Mindware 3.0</h3>
                  <p style={{ color: '#fff' }}>We're a custom application company.</p>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 margin30">
                <div class="footer-col">
                  <h3 style={{ color: '#fff' }}>Contact Us</h3>
                  <ul style={{listStyleType: 'none'}} class="list-unstyles contact">
                    <li>
                      <p>
                        <strong style={{ color: '#fff' }}>Mail Us: </strong>
                        <a style={{ color: '#fff', textDecoration: 'none'}} href="mailto:support@seriousmindware.com">support@seriousmindware.com</a>
                      </p>
                    </li>
                    <li>
                      <p style={{ color: '#fff' }}>
                        <strong style={{ color: '#fff' }}>Phone: </strong>
                        845-520-7450
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <div id="copyright" class="footer-btm">
                  <span style={{ color: '#fff' }}>©2021 - Serious Mindware 3.0</span>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </body>
    </div>
  );
}

const styles = {
  navbarbrand: { fontWeight: '700', color: '#000', fontSize: 30, textDecoration: 'none' },
  pullLeft: { clear: 'both', display: 'flex', flexDirection: 'column', justifyContent: 'flex-begin' }
};

export default App;
